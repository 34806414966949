// PRODUCTION:
// const LOG_NAMES = "App.js:*"

// JEST DEBUG
// eslint-disable-next-line
let LOG_NAMES_DEV_OLD = `<App>:*,BL:*,<FlashTopBar>:*,<FlashMessage>:*,<FlashShare>:*,<Contacts>:*,<Issues>:*,<Statements>:*,<Ratings>:*,<Preview>:*,<Summary>:*,<Submit>:*,<FlashBottomBar>:*,<Register>:*,<FlashSwipeItem>:*,<FlashRankList>:*,<FlashExpand>:*,<FlashBasicComponent>:*,<FlashIssueChart>:*,<FlashReasonsChart>:*,<FlashRankCard>:*,<FlashSelectCard>:*,<FlashSelectList>:*,<Rankings>:*,<IDash>:*,<SDash>:*,<FlashSDashTable>:*,<FlashIDashTable>:*, <FlashSDashTable>:*, <FlashTrendMicroChart>:*,<FlashChangeMicroChart>:*,<FlashCountMicroChart>:*,<FlashIssueCountsMap>:*,-*:trace,
<FlashIssueTrendsChart>:*,<Flash2SidesChart>:*,
<TestDash>:*,
<DashBarChart>:*,
<DashBarChart>:*,
<DashTable>:*,
<DashMap>:*,
<IDashState>:*,
<IDashUscd>:*,
<IDash>:*,
<DashLineChart>:*,
<DashTrend>:*,
<DashGenTable>:*,
<DashGen2Table>:*,
<Dash3ColTable>:*,
<DashBar2Chart>:*,
<Dash2Map>:*,
<DashGeoFilter>:*,
<DashBar2Chart>:*,
<DashMapV3>:*,
<DashMapV4>:*,
<DashMapV5>:*,
<LoadTest>:*,
<AppContext>:*,
<JsonPreloader>:*,
<Sentiment>:*,
<FlashSentimentPad>:*, 
App.test:debug,
-App.test:trace,
FlashIssueChart.test:debug,
<Questions>:*,
<Overview>:*,
<SignIn>:*,
<Database>:*,
-FlashIssueChart.test:trace`

const LOG_NAMES_DEV = `*:error,*:debug,*:info,-*:trace`
const LOG_NAMES_PROD = `*:error,-*:debug,-*:info,-*:trace`

//console.log("App.config.js: process.env =", process.env)
//console.log("App.config.js: process.env.READ_APP_LOG_MODE =", process.env.REACT_APP_LOG_MODE)

let LOG_MODE
if(process.env.REACT_APP_LOG_MODE) {
    LOG_MODE = process.env.REACT_APP_LOG_MODE
} else if(process.env.VAPOR_LOG_MODE) {
    LOG_MODE = process.env.VAPOR_LOG_MODE
}

console.log("App.config.js: LOG_MODE =", LOG_MODE)

let LOG_NAMES
switch (LOG_MODE) {
case "devshell":
    LOG_NAMES = LOG_NAMES_DEV
    break
case "dev":
    LOG_NAMES = LOG_NAMES_DEV
    break
default:
    //LOG_NAMES = LOG_NAMES_PROD
    LOG_NAMES = LOG_NAMES_DEV
}

const setupLog = (name) => {
    let log = {}
    let debug = require("debug")
    // debug.enable("*:*,<Index>:*")
    debug.enable(LOG_NAMES)
    
    log.debug = debug(name + ":debug")
    log.trace = debug(name + ":trace")
    log.info = debug(name + ":info")
    log.error = debug(name + ":error")
    
    return log
}

//
// CommonJS (for require)
//
/*
module.exports = {
    LOG_NAMES: LOG_NAMES,
    getLogger: setupLog
}
*/

//
// ES6 (for import)
//
export default {
    LOG_NAMES: LOG_NAMES,
    getLogger: setupLog
}
