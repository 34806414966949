// -*-mode: rjsx -*-
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
//import { getFirestore } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
 apiKey: "AIzaSyCmttHUYlHXbBOzBhSt3W0VTdumcbQJkG0",
  authDomain: "lightning-prod-01-firebase-01.firebaseapp.com",
  projectId: "lightning-prod-01-firebase-01",
  storageBucket: "lightning-prod-01-firebase-01.appspot.com",
  messagingSenderId: "793393868702",
  appId: "1:793393868702:web:73281bd06055b2db64f08f",
  measurementId: "G-P8R8FKX77Q"
};


const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

export { app, db, auth }
