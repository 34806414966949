import { createTheme } from "@mui/material/styles"
// eslint-disable-next-line
import grey from "@mui/material/colors/grey"
// eslint-disable-next-line
import green from "@mui/material/colors/green"
// eslint-disable-next-line
import lightGreen from "@mui/material/colors/lightGreen"
// eslint-disable-next-line
import indigo from "@mui/material/colors/indigo"
// eslint-disable-next-line
import blue from "@mui/material/colors/blue"
// eslint-disable-next-line
import red from "@mui/material/colors/red"
// eslint-disable-next-line
import brown from "@mui/material/colors/brown"
// eslint-disable-next-line
import yellow from "@mui/material/colors/yellow"
// eslint-disable-next-line
import lime from "@mui/material/colors/lime"
// eslint-disable-next-line
import amber from "@mui/material/colors/amber"
// eslint-disable-next-line
import lightBlue from "@mui/material/colors/lightBlue"
// eslint-disable-next-line
import purple from "@mui/material/colors/purple"
// eslint-disable-next-line
import teal from "@mui/material/colors/teal"


const gl = {}
//
// PALETTTE
//
gl.palette = {
    primary: {
	//	    light: '#83b9ff',
	//	    main: '#448aff',
	//	    dark: '#005ecb',
	//	    contrastText: '#ffffff', // white on bluish

	light: blue[400],
	main: "#438CFF",
	dark: blue[900],
	
	contrastText: '#ffffff', // white on bluish
    },
    
    secondary: {
//	light: yellow[200],
	//	light: yellow[100],
	//	light: lightGreen[100],
	light: yellow.A100, // OK
	main: grey[200],
	dark: green[900],
    },

    custom: {
	// blue
//	scrollDark: "#438CFF",
//	scrollLight: blue[400],

	//	scrollDark: grey[600],
	scrollDark: grey[500],
	scrollLight: grey[100],
	// 	scrollLight: green[200], // works
	//scrollDark: green[500],
	// scrollDark: "#63c167", // works
	itemHover: grey[100],
	selected: green[100],
	selectedHover: lightGreen[100],
	//	flashBar: lightBlue[300], OK
        //	flashBar: teal[200], // this one worked
	// flashBarForeground: blue[900],
	// flashBarForeground: "#002060", mike's 
	//	flashBarBackground: "#99ffcc", mike's
	// flashBarForeground: "#000000", // https://www.schemecolor.com/blue-green-yellow.php
	// flashBarForeground: blue[900],
	// flashBarForeground: "#002060", // mike's 
	// flashBarBackground: "#90dcd0", // https://www.schemecolor.com/blue-green-yellow.php


	// similar to countable
	//flashBarForeground: "#FFFFFF", // https://www.schemecolor.com/blue-green-yellow.php WOKRING
	// flashBarBackground: "#90dad0", // https://www.schemecolor.com/blue-green-yellow.php
	//	flashBarBackground: "#90dcd0", // https://www.schemecolor.com/blue-green-yellow.php

	// flashBarForeground: blue[900], // https://www.schemecolor.com/blue-green-yellow.php WOKRING
	flashBarForeground: "#00235a",
	flashBarBackground: "#80cdef", // https://www.schemecolor.com/blue-green-yellow.php
	//        flashBar: red[300],
	nextButton: green[600],
	nextButtonLight: green[400],

	rightButton: green[600],
	rightButtonLight: green[400],

	leftButton: red[600],
	leftButtonLight: red[400],
	
    },

    test: {
	fg1: grey[900],
        bg1: lime[100],
	
	fg2: grey[100],
        bg2: green[300],

	fg3: yellow[100],
        bg3: grey[500],

	mainFG: "#000000",
	mainBG: "#FAFAFA",

	cardFG: "#000000",
	cardBG: "#FFFFFF",
	
    },
}


const ShareTheme = createTheme({

    breakpoints: {
	values: {
	    "xs": 0,
	    "sm": 350,
	    "md": 600,
	    "lg": 1280,
	    "xl": 1920,
	}
    },

    //
    // TYPOGRAPHY
    //
    typography_default: {
	fontFamily: [
	    '-apple-system',
	    'BlinkMacSystemFont',
	    '"Segoe UI"',
	    'Roboto',
	    '"Helvetica Neue"',
	    'Arial',
	    'sans-serif',
	    '"Apple Color Emoji"',
	    '"Segoe UI Emoji"',
	    '"Segoe UI Symbol"',
	].join(','),
	//	h6: {
	//	    fontSize: 100,
	//	},
    },
    typography: {
	//	h6: {
	//	    fontSize: 100,
	//	},

	// what we've switched for fonts
	/*
	fontFamily: [
	    'Arial',
	    '"Helvetica Neue"',
	    "Helvetica",
	    'sans-serif'
	]
	*/
	/*
	fontFamily: [
	'-apple-system',
	    'BlinkMacSystemFont',
	    '"Segoe UI"',
	    'Roboto',
	    '"Helvetica Neue"',
	    'Arial',
	    'sans-serif',
	    '"Apple Color Emoji"',
	    '"Segoe UI Emoji"',
	    '"Segoe UI Symbol"',
	]
	*/

	fontFamily: [
	    // "Segoe UI Historic",
	    "Segoe UI",
	    "Segoe UI",
	    "Helvetica",
	    "Arial",
	    "sans-serif"
	    ]
	    .join(","),
	fontFamily_Impart: [ // for testing
	    'Impact',
	    'Haettenschweiler',
	    '"Franklin Gothic Bold"',
	    'Charcoal',
	    '"Helvetica Inserat"',
	    '"Bitstream Vera Sans Bold"',
	    '"Arial Black"',
	    '"sans serif"'
	].join(","),
    },
    
    messageBackground: "rgba(100,100,100,0.6)",
    submitBackground: "rgba(76,175,80,0.6)",
    registrationBackground: "rgba(900,900,900,0.9)",

    palette: {
	...gl.palette
    },
    

    //
    // CSS common to all components
    //
    css: {

        // blue heading
	appbarRoot: {
            height: 64,
            backgroundColor: gl.palette.primary.main,
	    zIndex: 1000,
	},

        // 
        // unused (kind of)
        // 
        appbarMessage: {
	    color: "#FFFFFF"
        },

	appheadRoot: {
            marginTop: 64,
            height: 64,
	    background: gl.palette.secondary.light,// good
//	    border: "none",
//	    boxShadow: "none",

//	    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",

            /* kinda works, small 
	    boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2), 
                        0px 1px 1px 0px rgba(0,0,0,0.14), 
                        0px 2px 1px -1px rgba(0,0,0,0.12);`,
*/
//            boxShadow: "none",

            // just a small transparent border/line
	    boxShadow_old: `0px 1px 3px 0px rgba(0,0,0,0.2), 
                        0px 1px 1px 0px rgba(0,0,0,0.0), 
                        0px 2px 1px -1px rgba(0,0,0,0.0);`,

	    boxShadow: `0px 0px 7px 0px rgba(0,0,0,0.2), 
                        0px 1px 1px 0px rgba(0,0,0,0.0), 
                        inset 0px 2px 1px -1px rgba(0,0,0,0.0)`,

            
            zIndex: 900,
            justifyContent: "center", // vertical centering
            textAlign: "center", // horizontal centering
	    //
	    // mobile
	    // 
	    mobile: {
		height: 64
	    }
	},

	bnavbarRoot: {
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 1,
            backgroundColor: grey[200],
            // backgroundColor: theme.palette.primary.light,
            // backgroundColor: gl.palette.secondary.main,        
	},

	appheadMessage: {
	    //            padding: 100,
	    //            width: "100%",
	    //            textAlign: "center",
	    fontWeight: 600,
	},

	contentDiv: {
            padding: 0,
            marginTop: 256,
            marginBottom: 90,
            marginLeft: "auto",
            marginRight: "auto",

            //
            // desktop - if more than 600
            //
	    desktop: {
		marginTop: 279, //change this after appheader changes
		maxWidth: 600
	    }
	},

	separatorDiv: {
            display: "flex",
            alignItems: "center",
            margin: 0,
            padding: 0,
            paddingTop: 5,
            paddingBottom: 2,
            paddingLeft: 30,
//            paddingBottom: -50,
            width: "100%",
            backgroundColor: gl.palette.primary.light,
            color: gl.palette.primary.contrastText,

            //
            // desktop - if more than 600
            //
	    desktop: {
		// next to just to make them protrude
		// a bit more - better than sunken in
		// on a big screen
		width: 602,
		height: 34,
		marginTop: 10, // separate sections
		marginBottom: -3,
		marginLeft: -1,
                paddingTop: 5,
                paddingBottom: 2,
		borderRadius: 5,
		boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);",
		
            },

	},

	checkedCircle: {
            color: grey[700],
            // color: theme.palette.primary.main,
            // fontSize: 35
            fontSize: 50        
	},

	checkedCircleFilled: {
            color: grey[100],
            backgroundColor: grey[700],
            // color: theme.palette.primary.main,
            // fontSize: 35
            fontSize: 50        
	},
	
	uncheckedCircle: {
            color: grey[700],
            // color: theme.palette.primary.main,
            // fontSize: 35
	    fontSize: 50	    
	},

	numbers: {
	    flashBar: {
		height: 64, // not used currently
	    }
	},

	barShadows: {
	    boxShadow_original: `0px 1px 3px 0px rgba(0,0,0,0.2), 
                                 0px 1px 1px 0px rgba(0,0,0,0.14), 
                                 0px 2px 1px -1px rgba(0,0,0,0.12);`,

	    boxShadow_older: `0px 3px 3px 0px rgba(0,0,0,0.2), 
                        0px 1px 1px 0px rgba(0,0,0,0.14), 
                        0px 2px 1px -1px rgba(0,0,0,0.12);`,

	    boxShadow: `0px 3px 3px 0px rgba(0,0,0,0.2), 
                        inset 0px 1px 1px 0px rgba(0,0,0,0.14), 
                        0px 2px 1px -1px rgba(0,0,0,0.12)`
	    
	}
    }
})


export default ShareTheme
