// -*-mode: rjsx -*-
import { db, auth } from "./FirebaseApp.mjs"
import { query, collection,
	 addDoc, getDocs,
         getDoc, doc,
	 where, orderBy,
	 limit,
         onSnapshot,
	 Timestamp,
         serverTimestamp,
       } from "firebase/firestore" 


const permissionsCollectionName = "test-permissions-01"
const organizationsCollectionName = "test-organizations-01"
const answersColName = "test-answers-01"
const permRef = collection(db, permissionsCollectionName)


const hasUserPermission = async (mode, userId, organizationId) => {
    // TODO: organizations should really be a subcollection
    //       because we might have some people be able to read
    //       more than 10 organizations, so we need to do a
    //       subcollection search
    const q = query(permRef,
		    where("userId", "==", userId),
		    orderBy("timestamp", "desc"),
		    limit(1))
    
    const permissionsSnap = await getDocs(q)
    
    let found = false
    if (permissionsSnap.size !== 0) {
	console.log("permissionsSnap =", permissionsSnap.size)
	permissionsSnap.forEach(doc => {
	    //	    console.log(doc.id, "=>", doc.data())
	    doc.data().organizations.forEach(org => {
		console.log(org.id, "=>", org.read)
		if (org.id === organizationId && org[mode] === true) {
		    found = true
		}
	    })
	})
    }

    return found
}

const getUserOrgs = async (userId) => {
    const permRef = collection(db, permissionsCollectionName)
    const q = query(permRef,
		    where("userId", "==", userId),
		    orderBy("timestamp", "desc"),
		    limit(1))
    
    const permissionsSnap = await getDocs(q)


    let permissionsDoc
    // we're just accessing a signle element here
    // it will be only the last one
    permissionsSnap.forEach(_doc => {
	permissionsDoc = _doc
    })

    const orgs = []
    if(permissionsDoc && permissionsDoc.exists()) {
        const permOrgs = permissionsDoc.data().organizations
        
        for(let oi in permOrgs) {
            console.log("permOrg =", permOrgs[oi])
            const orgSnap = await getDoc(doc(db, organizationsCollectionName, permOrgs[oi].id))
            console.log("orgSnap.data =", orgSnap.data())
            orgs.push(
                {
                    ...permOrgs[oi],
                    ...orgSnap.data()
                }
            )
            console.log("orgs =", orgs)
        }
        
    } else {
        console.log("ERROR: could not get ogs")
    }

    return orgs
    //return doc.data().organizations
}



// take { sections:[{questions:[]}]} structure
// and return flat array of questions
const sectionedDataToFlatQuestions = (data) => {

    const flatQuestions = [
    ]

    for(let dsi in data.sections) {
        let docSection = data.sections[dsi]
        console.log("docSection.header =", docSection.header)
        for(let dqi in docSection.questions) {
            let docQuestion = docSection.questions[dqi]
            console.log("docQuestion =", docQuestion)
            console.log("dqi =", dqi)
            flatQuestions.push({
                doPrintSectionHeader: dqi == 0 ? true : false,
                header: docSection.header,
                slug: docQuestion.slug,
                text: docQuestion.text
            })
        }
    }

    return flatQuestions
}

const flatQuestionsToSectionedData = (questions) => {
        return questions.reduce((total, current) => {
        let isFound = false
        for(let si in total.sections) {
            if(total.sections[si].header === current.header) {
                isFound = true
                total.sections[si].questions.push(current) // push the question into the section
                break;
            }
        }

        if(!isFound) {
            // create the section first
            total.sections.push({
                header: current.header,
                questions: [
                    {...current}
                ]
            })
        }

        return total
        
    },{ sections: []})
}


const findQuestion = (data, slug) => {
    for (let si in data.sections) {
        console.log("section = ", data.sections[si])
        for (let qi in data.sections[si].questions) {
            let question = data.sections[si].questions[qi]
            console.log("question = ", question)
            if(slug === question.slug) {
                console.log("FOUND")
                return question
            }
        }
    }
    return {
        answer: "blank answer"
    }
}

const getDistinctQuestions = async (orgId) => {
    const answersRef = collection(db, answersColName)
    // NOTE: Get the latest answer - it "should" have all the
    // distict questions, but this is very hokey
    // and we should have a better way to get distict
    // anwers for organization
    const q = query(answersRef,
		    where("orgId", "==", orgId),
		    orderBy("timestamp", "desc"),
		    limit(3))

    const answersSnap = await getDocs(q)

    let flatQuestions = [
    ]

    answersSnap.forEach(doc => {
        flatQuestions = [
            ...sectionedDataToFlatQuestions(doc.data())
        ]
    })


    const distinctQuestions = flatQuestions.reduce((total, current) => {
        console.log("total =",total)
        console.log("current =",current)
        let isFound = false
        for(let ti in total) {
            if(total[ti].slug === current.slug
               && total[ti].text === current.text) {
                isFound = true; break
            }
        }
        if(!isFound) {
            total.push(current)
        }
        return total
    },[])
    

    const sectionedData = flatQuestionsToSectionedData(distinctQuestions)
    
    //console.log("distinctQuestions =", distinctQuestions)
    //console.log("sectionedData =", sectionedData)


    return sectionedData
}


// takes out the section level
const flattenSections = (data) => {

    console.log("flattenSections(): data =", data) 
    
    const flatSections = [
    ]

    for(let dsi in data.sections) {
        let docSection = data.sections[dsi]
        console.log("docSection.header =", docSection.header)
        for(let dqi in docSection.questions) {
            let docQuestion = docSection.questions[dqi]
            console.log("docQuestion =", docQuestion)
            console.log("dqi =", dqi)
            flatSections.push({
                //                doPrintSectionHeader: dqi == 0 ? true : false,
                //                header: docSection.header,
                //                slug: docQuestion.slug,
                //                text: docQuestion.text
                ...docSection,
                user: data.user,
            })
        }
    }

    console.log("flattenSections(): returning flatSections =", flatSections) 

    return flatSections
}

const getOrgAnswers = async (orgId, slug) => {
    console.log("orgId =", orgId)


    const answersRef = collection(db, answersColName)
    /*
    const q = query(answersRef,
		    where("orgId", "==", orgId),
		    orderBy("timestamp", "desc"),
		    limit(3))
    */

    const q = query(answersRef,
		    where("orgId", "==", orgId),
		    orderBy("timestamp", "desc"))


    const answersSnap = await getDocs(q)


    let answers = [
    ]


    answersSnap.forEach(doc => {
        const data = doc.data()
        let userId = data.user
        console.log("userId =", userId)
        for(let dsi in data.sections) {
            for(let dqi in data.sections[dsi].questions) {
                const question = data.sections[dsi].questions[dqi]
                console.log("question =", question)
                if(question.slug === slug) {
                    answers.push({
                        userId: userId,
                        answer: question.answer
                    })
                }
            }
        }
    })

    
    console.log("answers =", answers)

    
    return answers
}
    


export {
    hasUserPermission, getUserOrgs,
    getDistinctQuestions, findQuestion,
    getOrgAnswers
}
