// -*-mode: rjsx -*-
import React, { useEffect, useState } from 'react'
import { collection, getDocs, addDoc } from 'firebase/firestore';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Navigate, useRoutes, useNavigate, useNavigationType } from "react-router-dom"
import {db, auth} from "./FirebaseApp"
import { hasUserPermission, getUserOrgs,
         getDistinctQuestions, getOrgAnswers,
         findQuesiton } from "./Database.mjs"
import { CssBaseline, Select, MenuItem,
         AppBar, Toolbar,
         Typography,  Container,
         Box, InputLabel, FormControl } from "@mui/material"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';


// MUI
import { makeStyles, withStyles } from "@mui/styles"


import appConfig from "../App.config.js"
const log = appConfig.getLogger("<Overview>");


const QuestionTextField = styled(TextField)({
    '& .Mui-focused': {
        backgroundColor: "white"
    },
    '& .MuiInput-underline:after': {
        //borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            //borderColor: 'red',
        },
        '&:hover fieldset': {
            //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
            //borderColor: 'green',
        },
    },
})


const useDefaultStyles = makeStyles(theme => ({
    textField: {
        // background: "none",
    },

    questionParagraph: {
        marginTop: "15px"
    },

    questionText: {
        fontSize: "1.2rem",
        '&:hover': {
            fontSize: "1.25rem",
            fontWeight: "bold",
            cursor: "pointer"
        }
    },

    sectionHeader: {
        marginTop: "40px",
        //marginBottom: "-15px"
    },
    
    submitBoxContainer: {
        marginTop: "50px"
    }
    
}))


let initData = {
    sections: [
    ]
}

const Overview = props => {


    const classes = {
        ...useDefaultStyles(),
    }

    const [isSignedIn, setIsSignedIn] = useState(false)
    const [isUserReady, setIsUserReady] = useState(false)
    const [user, setUser] = useState()
    const [orgs, setOrgs] = useState([])
    const [orgId, setOrgId] = useState('')
    const [data, setData] = useState(initData)
    const [view, setView] = useState("questions")
    const [summaryQuestion, setSummaryQuestion] = useState({})
    const [answers, setAnswers] = useState([])

    const handleOrgChange = (event) => {
        (async () => {
            setOrgId(event.target.value)
            const newData = await getDistinctQuestions(event.target.value)
            log.debug("newData =", newData)
            setData(newData)
        })()
    }

    const handleQuestionClick = question => event => {
        //log.debug("handleQuestionClick(): question =", question)
        (async () => {
            setSummaryQuestion(question)
            setAnswers(await getOrgAnswers(orgId, question.slug))
            setView("summary")
        })()
    }


    const handleSubmitClick = question => event => {
        log.debug("handleSubmitClick(): question =", question)
        log.debug("handleSubmitClick(): summary =", question.summary)
        
        setView("questions")
    }

    const handleSummaryInputChange = question => event => {
        log.debug("handleSummaryInputChange(): question =", question)
        log.debug("handleSummaryInputChange(): event.target.value =", event.target.value)
        question.summary = event.target.value

        setSummaryQuestion(question)
    }


    /*
      https://reactrouterdotcom.fly.dev/docs/en/v6/api#usenavigationtype
      https://blog.devgenius.io/react-tips-back-button-stop-event-bubbling-merging-states-5aca03bf50f9
      https://usehooks.com/useRouter/
    */
    const navigationType = useNavigationType()
    const navigate = useNavigate()
    
    useEffect(() => {
        return () => {
            console.log("navigateType =", navigationType)
            //return (<Navigate replace to="/overview"/>)
            if(navigationType === "POP") {
                console.log("org =", orgId)
                setView("questions")
                navigate(`/overview`) //TODO: kind of works, but does not, we need to stop events here somehow
                                      // so that we don't go into signin at all
                //const org = JSON.parse(JSON.strigify(org))
                //setOrg(org)
                //navigate(`/overview?org=${org}`)
            } else {
            }
        }
    },[navigationType])

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if(user) {
                log.debug("useEffect(): 1: signed in user =", user)
                setUser(user)
                setIsSignedIn(true)
                setIsUserReady(true)
            } else {
                setIsSignedIn(false)
                setIsUserReady(true)
            }
        }, (error) => {
            log.debug("useEffect(): 1: ERROR: error =", error)
        })

        return () => unregisterAuthObserver() // make sure we unregister firebase observers when component unmounts
    }, [])

    useEffect(() => {
        (async () => {
            log.debug("useEffect(): 2: user =", user)
            if(user) {
                const userId = user.uid
                const orgs = await getUserOrgs(userId)
                log.debug("useEffect(): 2: orgs =", orgs)
                setOrgs(orgs)
            }
        })()
    },[user])


    if(!isUserReady) {
        //return (<Navigate replace to="/signin"/>)
        log.debug("main: User is not ready...")
        return (
	    <div>
              Loading...
	    </div>
        )
    } else {
        if(!isSignedIn) {
            // redirect to sign in
            log.debug("isUserReady = ", isUserReady)
            log.debug("isSignedIn = ", isSignedIn)
            log.debug("Redirecting...")
            return (<Navigate replace to="/signin?mode=select&signInSuccessUrl=overview"/>)
        }
    }

    // User is signed in and ready to go 

    return (
        <React.Fragment>
          <CssBaseline />
          <AppBar>
            <Toolbar>
              <Typography variant="h6" component="div">
                Overview
              </Typography>
            </Toolbar>
          </AppBar>
          <Toolbar id="back-to-top-anchor" />
          <Container>
            <Box style={{ marginTop: "80px" }} sx={{ my: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orgId}
                  label="Organization"
                  onChange={handleOrgChange}>
                  {orgs.map(org => {
                      log.debug("inside org =", org)
                      return (<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Box>
          </Container>
          <Container>
            {view === "questions" &&
             <Box sx={{ my: 2 }}>
               {data.sections.map(section => {
                   log.debug("section =", section)
                   return section.questions.map(question => {
                       log.debug("question =", question)
                       return <div key={`${question.slug}-div`}>
                      {question.doPrintSectionHeader &&
                       <div className={classes.sectionHeader}>
                         <Typography variant="h5" component="div">
                           {section.header}
                         </Typography>
                       </div>
                      }
                      <div className={classes.questionParagraph}>                           
                        <Typography variant="subtitle1" gutterBottom component="div">
                          <div onClick={handleQuestionClick(question)} className={classes.questionText}>{question.text}</div>
                        </Typography>
                      </div>
                    </div>
                   })
               })}
             </Box>
            }

            {view === "summary" &&
             <Box sx={{ my: 2 }}>
               <div className={classes.questionParagraph}>                           
                 <Typography style={{fontSize: "1.2rem"}} variant="subtitle1" gutterBottom component="div">
                   {summaryQuestion.text}
                 </Typography>
               </div>
               <QuestionTextField className={classes.textField}
                                  id={summaryQuestion.slug}
                                  variant="filled"
                                  size="small"
                                  fullWidth
                                  multiline
                                  maxRows={10}
                                  minRows={4}
                                  value={summaryQuestion.summary}
                                  onChange={handleSummaryInputChange(summaryQuestion)}/>
               <Button style={{marginTop: "1.2rem"}} variant="contained"
                       onClick={handleSubmitClick(summaryQuestion)}>
                 Submit Summary
               </Button>
               <Typography style={{marginTop: "1rem", fontSize: "1.2rem"}} variant="subtitle1" gutterBottom component="div">
                   Answers:
                 </Typography>
             {answers.map(answer => <div style={{display: "flex", marginTop:"10px"}} key={`${answer.userId}-key`}><QuestionAnswerIcon style={{marginRight: "15px"}}/><Typography variant="subtitle1">{answer.answer}</Typography></div>)}
             </Box>
            }
          </Container>
        </React.Fragment>
    )
}

export default Overview
