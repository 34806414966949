// -*-mode: rjsx -*-

import { BrowserRouter as Router,
         Route,
         Routes,
         Navigate
       } from "react-router-dom"

// MUI
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline";

// Share
import ShareTheme from "./components/ShareTheme.js"
import Questions from "./components/Questions.js"
import Overview from "./components/Overview.js"
import SignIn from "./components/SignIn.js"

function App() {
    return (
	<ThemeProvider theme={ShareTheme}>
          <Router>
            <Routes>
              <Route path="/_ah/warmup" element={<Questions/>}/>
              <Route path="/signin" element={<SignIn/>}/>
              <Route path="/questions" element={<Questions/>}/>
              <Route path="/overview" element={<Overview/>}/>
              <Route path="/" element={<Navigate replace to="/questions" />}/>
              <Route path="/*" element={<Navigate replace to="/questions" />}/>
            </Routes>
          </Router>
	</ThemeProvider>
  );
}

export default App;
