// -*-mode: rjsx -*-

import React from "react"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Button from "@mui/material/Button"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import CssBaseline from "@mui/material/CssBaseline"
import {  Box, InputLabel, FormControl } from "@mui/material"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import Container from "@mui/material/Container"
import Fab from "@mui/material/Fab"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import Zoom from "@mui/material/Zoom"
import TextField from "@mui/material/TextField"
import { alpha, styled } from '@mui/material/styles';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getDocs,
         addDoc, serverTimestamp,
       } from "firebase/firestore";
import * as Uuid from "uuid"
import { useSearchParams } from "react-router-dom"
import OrgSearch from "./OrgSearch.js"

import { db, auth } from "./FirebaseApp.mjs"

// MUI
import { makeStyles, withStyles } from "@mui/styles"

// LOG
import appConfig from "../App.config.js"
const log = appConfig.getLogger("<Questions>");

// this "user" is not a user that's been logged in, so keep it as "user"
// not to mix it up with the other users
let initData = {
    isSubmitted: false,
    doAllowResubmit: false,
    user: "test-user-01",
    orgId: "test-organization-01",
    sections: [
        {
            header: "In terms of the process of the consultation:",
            questions: [
                {
                    slug: "what-were-the-main-steps",
                    type: "short",
                    doPrintSectionHeader: true,
                    text: "What were the main steps taken in the diocese?",
                    answer: ""
                },
                {
                    slug: "what-was-done-to-engage",
                    type: "long",
                    text: "What was done to engage as many participants as possible and to reach out to the peripheries?",
                    answer: ""
                }
            ]
        },
        {
            header: "Among the feedback from the local meetings:",
            questions: [
                {
                    doPrintSectionHeader: true,
                    slug: "what-was-particularly-significant",
                    type: "short",
                    text: "What was particularly significant, surprising or unexpected?",
                    answer: ""
                },
                {
                    slug: "overall-what-was-the-holy-spirit",
                    type: "long",
                    text: "Overall, what has the Holy Spirit inspired the community to see regarding the current reality of synodality in the local church, including the present lights and shadows?",
                    answer: ""
                }
            ]
        }
    ]
}


const useDefaultStyles = makeStyles(theme => ({
    textField: {
        // background: "none",
    },

    questionParagraph: {
        marginTop: "50px"
    },

    sectionHeader: {
        marginTop: "50px",
        marginBottom: "-15px"
    },
    submitBoxContainer: {
        marginTop: "50px"
    }
    
}))


const QuestionTextField = styled(TextField)({
    '& .Mui-focused': {
        backgroundColor: "white"
    },
    '& .MuiInput-underline:after': {
        //borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            //borderColor: 'red',
        },
        '&:hover fieldset': {
            //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
            //borderColor: 'green',
        },
    },
});


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


const savedData = JSON.parse(localStorage.getItem("live.lightninig.share.data"))
log.debug("init: savedData = ", savedData)

if(savedData) {
    initData = savedData
} else {
    initData.user = Uuid.v4()
}

// override if we're a test user

export default function Questions(props) {

    const classes = {
        ...useDefaultStyles(),
    }

    const [data, setData] = useState(initData)
    const [searchParams] = useSearchParams()

    log.debug("main: searchParams =", searchParams)
    const overUser = searchParams.get("user")
    log.debug("main: overUser =", overUser)
    log.debug("main: user =", initData.user)

    useEffect(() => {
        log.debug("main: searchParams =", searchParams)
        const overUser = searchParams.get("user")
        log.debug("overUser =", overUser)
        // DoAllow override only if we have have an
        // overriding user in the search string
        if(overUser && overUser.substring(0,4) === "test") {
            let newData = JSON.parse(JSON.stringify(data))
            newData.user = overUser
            newData.doAllowResubmit = true
            setData(newData)
        } else {
            let newData = JSON.parse(JSON.stringify(data))
            newData.doAllowResubmit = false
            setData(newData)
        }

    },[searchParams])
    
    const handleAnswerInputChange = slug => event => {
        log.trace("slug =", slug);
        let newData = JSON.parse(JSON.stringify(data))
        let question = findQuestion(newData, slug)
        question.answer = event.target.value
        log.trace("question =", question);
        setData(newData)
    }

    const handleSubmit = () => {
        log.debug("handleSubmit(): data =", data);


        if(data.isSubmitted && !data.doAllowResubmit) {
            log.debug("handleSubmit(): Data has already been submitted before")
            return
        }

        // Data is not submitted -> go ahead
        (async function() {
            try {

                let newData = JSON.parse(JSON.stringify(data))
                newData.isSubmitted = true
                newData.doAllowResubmit = false // default
                newData.timestamp = serverTimestamp()
                log.debug("handleSubmit.function(): db =", db)
                const ansRef = collection(db, "test-answers-01")

                log.debug("handleSubmit.function(): ansReft =", ansRef)

                const docRef = await addDoc(collection(db, "test-answers-01"), newData)
                log.debug("handleSubmit.function(): Document written with ID: ", docRef.id);

                newData.lastDocRef = docRef.id
                
                localStorage.setItem("live.lightninig.share.data", JSON.stringify(newData))
                
                setData(newData) // set data after we save it locally
            } catch (e) {
                console.error("handleSubmit.function(): Error adding document: ", e);
            }
        })()

    }

    const findQuestion = (data, slug) => {
        for (let si in data.sections) {
            log.trace("section = ", data.sections[si])
            for (let qi in data.sections[si].questions) {
                let question = data.sections[si].questions[qi]
                log.trace("question = ", question)
                if(slug === question.slug) {
                    log.trace("FOUND")
                    return question
                }
            }
        }
        return {
            answer: "blank answer"
        }
    }

    log.trace("main: data =", data)
    
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div">
            Questions
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
          <Container>
            <Box style={{ marginTop: "80px" }} sx={{ my: 2 }}>
                <OrgSearch />
            </Box>
          </Container>
      <Container>
        <Box sx={{ my: 2 }}>
          {data.sections.map(section => {
              log.trace("section =", section)
              return section.questions.map(question => {
                  return <div key={`${question.slug}-div`}>
                           {question.doPrintSectionHeader &&
                            <div className={classes.sectionHeader}>
                              <Typography variant="h5" component="div">
                                {section.header}
                              </Typography>
                            </div>
                           }

                  <div className={classes.questionParagraph}>                           
                    <Typography style={{fontSize: "1.2rem"}} variant="subtitle1" gutterBottom component="div">
                      {question.text}
                    </Typography>
                           {question.type === "short" &&
                            <QuestionTextField className={classes.textField}
                                               id={question.slug}
                                               variant="filled"
                                               size="small"
                                               fullWidth
                                               margin="dense"
                                               value={(findQuestion(data, question.slug)).answer}
                                               onChange={handleAnswerInputChange(question.slug)}/>
                           }

                           {question.type === "long" &&
                            <QuestionTextField className={classes.textField}
                                               id={question.slug}
                                               variant="filled"
                                               size="small"
                                               fullWidth
                                               multiline
                                               maxRows={10}
                                               minRows={4}
                                               value={(findQuestion(data, question.slug)).answer}
                                               onChange={handleAnswerInputChange(question.slug)}/>
                    
                           }
                  </div>
                         </div>
              })
          })}
        </Box>
      </Container>
      <Container className={classes.submitBoxContainer}>
        <Box sx={{ my: 2 }}>
          <Button variant="contained"
                  onClick={handleSubmit}
                  disabled={data.isSubmitted && !data.doAllowResubmit}
          >
            Submit
          </Button>
        </Box>
      </Container>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
