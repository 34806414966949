// -*-mode: rjsx -*-
import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, useNavigate } from "react-router-dom"
import firebase from 'firebase/compat/app';
import * as firebaseui from "firebaseui" // we need this for firebase.auth.EmailAuthProvider.PROVIDER_ID
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button"
import Typography from '@mui/material/Typography';

import {db, auth} from "./FirebaseApp"

import appConfig from "../App.config.js"
const log = appConfig.getLogger("<SignIn>");

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
  
    callbacks: {
        signInSuccessWithAuthResult: (result, redirectUrl) => {
            log.debug("signInSuccessWithAuthResult(): result =", result)
            log.debug("signInSuccessWithAuthResult(): redirectUrl =", redirectUrl)
            return (<Navigate replace to={redirectUrl}/>)
        }
    }

};

          {/*              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /> */}

const SignIn = props => {

    const [isSignedIn, setIsSignedIn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            log.debug("SignedIn(): signed in user =", user)
            setIsSignedIn(!!user)
        })

        return () => unregisterAuthObserver() // make sure we unregister firebase observers when component unmounts
    }, [])


    if(!isSignedIn) {
        return (
        <React.Fragment>
          <CssBaseline />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <StyledFirebaseAuth uiCallback={ui => ui.disableAutoSignIn()} uiConfig={uiConfig} firebaseAuth={auth} />
          </Box>
        </React.Fragment>
        )
    }

    return (
        <Box sx={{ my: 2  }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
          <div>
            
            <Typography style={{marginBottom: "30px"}} variant="h6" component="div">
              Welcome {auth.currentUser.displayName}, you are now signed-in!
            </Typography>
            
            {/* <p>Welcome {auth.currentUser.displayName}, you are now signed-in!</p> */}
            {/* <a onClick={() => auth.signOut()}>Sign-out</a> */}
            <Box display="flex"
                 justifyContent="space-around"
                 alignItems="center"
            >
              <Button variant="contained"
                      onClick={() => auth.signOut()}>
                Sign Out
              </Button>
              <Button variant="contained"
                      onClick={() => navigate("/overview")}>
                Back to Overview
              </Button>
            </Box>
          </div>
          <div>

        </div>
          </Box>
        
    )
}

export default SignIn
